<template>
  <div class="base-box">
    <UploadInfoCom 
      :options="options"
      @uploadSuccess="uploadSuccessCallBack"
      />
    <SearchInput
      @searchInput="searchInput"
      @removeFile="removeFile"
      @cancelFileSync="cancelFileSync"
      />
    <SwitchTable 
      @changeAsyncType="changeAsyncType"
      :asyncInfo="asyncInfo"
      />
    <TableList
      v-if="showCom"
      :dataList="dataList"
      :total="total"
      :listType="listType"
      :pageNum="pageNum"
      :pageSize="pageSize"
      @operate="operate"
      @currentPageChange="currentPageChange"
      @pageSizeChange="pageSizeChange"
      />
  </div>
</template>

<script>
import dataMoveMixin from '@/api/dataMoveMixin'
import UploadInfoCom from '@/components/UploadInfoCom/Index.vue'
import TableList from '@/components/UploadInfoCom/TableList.vue'
import SearchInput from '@/components/SearchInput/Index.vue'
import SwitchTable from '@/components/UploadInfoCom/SwitchTable.vue'
export default {
  name: "contractPay",
  mixins: [dataMoveMixin],
  data() {
    return{
      // new-------------------------------------
      asyncInfo: {}, //切换组件数据
      isDisabled: false, // 按钮置灰
      listType: '0',
      showCom: true,
      dataList: [{
          label: "合同名称",
          prop: "contractName",
          minWidth: 100,
          id: '673',
          // costUrl: 'https://www.baidu.com/'
        }], // 列表数据
      total: 0, // 分页数据
      pageSize: 10, // 页面数据条数
      pageNum: 1, // 页数
      searchInfo: '', // 搜索内容
      deleteCount: 0, // 删除数量
      cancelCount: 0, // 取消数量
      loading: null,
      // new-------------------------------------
      options: [{
          value: 'COST',
          label: '老成本'
        }]
    }
  },
  components: {
    UploadInfoCom,
    TableList,
    SearchInput,
    SwitchTable
  },
  methods: {
    init() {
      this.pageNum = 1
      this.getContractPayInformation()
    },
    // 全部删除
    removeFile() {
      if (this.listType !== '0') {
        this.$message.warning('请在未同步列表下点击')
        return
      }
      this.loading = this.$loading({ 
        fullscreen: true,
        background: "rgba(255, 255, 255, 0.8)",
        text: "正在删除当前页..."
      })
      this.dataList.forEach(item => {
        this.deleteRowMessage(item, 'all')
      })
    },
    // 全部取消同步
    cancelFileSync() {
      if (this.listType !== '1') {
        this.$message.warning('请在已同步列表下点击')
        return
      }
      this.loading = this.$loading({ 
        fullscreen: true,
        background: "rgba(255, 255, 255, 0.8)",
        text: "正在取消同步当前页..."
      })
      this.dataList.forEach(item => {
        this.cancelSyn(item, 'all')
      })
    },
    // new-------------------------------
    // 上传成功回调
    uploadSuccessCallBack() {
      this.init()
    },
    // 切换列表
    changeAsyncType(val) {
      this.showCom = false
      if (val === 'Y') {
        this.listType = '1'
      } else {
        this.listType = '0'
      }
      this.init()
    },
    // 分页-切换页码
    currentPageChange(val) {
      this.pageNum = val
      this.getContractPayInformation()
    },
    // 分页-却换pageSize
    pageSizeChange(val) {
      this.pageSize = val
      this.init()
    },
    // 搜索
    searchInput(val) {
      this.searchInfo = val
      this.init()
    },
    // 事件选择
    operate(type, val) {
      if (type === 'details') {
        this.goDetails(val)
      } else if (type === 'cancel') {
        this.cancelSyn(val)
      } else {
        this.deleteRowMessage(val)
      }
    },
    // 跳转详情页
    goDetails(val) {
      if (val.costUrl) {
        window.location.href = val.costUrl
      }
    },
    // 查询列表数据
    getContractPayInformation() {
      const params = {
        'syncStatus': this.listType,
        "searchInfo": this.searchInfo,
        "pageSize": this.pageSize,
        "pageNum": this.pageNum
      }
      this.$request.post(this.URL.contractPayList, params).then(res => {
        this.showCom = true
        if (res.code && res.code === '200') {
          // res.data.applyInfo.list.forEach(item => {
          //   item.planPayDate = item.planPayDate.split('T')[0]
          // })
          // 表格
          this.dataList = res.data.applyInfo.list
          // 分页
          this.pageNum = res.data.applyInfo.pageNum
          this.pageSize = res.data.applyInfo.pageSize
          this.total = parseInt(res.data.applyInfo.total)
          // 切换按钮
          if (this.listType === '0') {
            this.asyncInfo = {
              unsynchronized: res.data.applyInfo.total,
              synchronization: res.data.count - res.data.applyInfo.total
            }
          } else {
            this.asyncInfo = {
              unsynchronized: res.data.count - res.data.applyInfo.total,
              synchronization: res.data.applyInfo.total
            }
          }
          if (parseInt(this.asyncInfo.unsynchronized) === 0) {
            this.isDisabled = true
          } else {
            this.isDisabled = false
          }
        }
      }).catch(res => {
        this.$message.error(res.message)
      })
    },
    // 删除
    deleteRowMessage(val, type) {
      this.$request.post(this.URL.deletecontractPay + '?payId=' + val.id).then(res => {
        if (res.code && res.code === '200') {
          if (type && type === 'all') {
            this.deleteCount++
          } else {
            this.getContractPayInformation()
          }
        }
      }).catch(res => {
        if(this.loading) {
          this.loading.close()
        }
        this.$message.error(res.message)
      })
    },
    //取消已同步
    cancelSyn(val, type) {
      this.$request.post(this.URL.unSyncContractPay + '?payId=' + val.id).then(res => {
        if (res.code === '200') {
          if (type && type === 'all') {
            this.cancelCount++
          } else {
            this.$message.success("取消成功！")
            this.init()
          }
        }
      }).catch(() =>{
        // 关闭全局loading
        if(this.loading) {
          this.loading.close()
        }
      })
    }
    // new-------------------------------
  },
  mounted() {
    this.init()
  },
  watch: {
    'cancelCount': function(val) {
      if (val === this.dataList.length) {
        this.init()
        this.cancelCount = 0
        if(this.loading) {
          this.loading.close()
        }
      }
    },
    'deleteCount': function(val) {
      if (val === this.dataList.length) {
        console.log(val)
        this.getContractPayInformation()
        this.deleteCount = 0
        if(this.loading) {
          this.loading.close()
        }
      }
    }
  },
  created() {
  }
};
</script>
<style lang="less" scoped>
</style>